import { useMemo } from 'react';
import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCurrentRouteConfig } from 'src/app/routes';

export function MetaUrlContainer(): JSX.Element {
	const { resolveUrl } = useUtilityContext();
	const { pathname } = useLocation();
	const routeConfig = useCurrentRouteConfig();

	const canonicalUrl = useMemo(() => {
		if (routeConfig) {
			const { config, params } = routeConfig;

			if (config.meta?.canonicalUrl) {
				if (typeof config.meta.canonicalUrl === 'string') {
					return resolveUrl(config.meta.canonicalUrl);
				}
				return resolveUrl(config.meta.canonicalUrl({ pathname, params }));
			}
		}

		return resolveUrl(pathname);
	}, [routeConfig, pathname, resolveUrl]);

	return (
		<Helmet>
			{/* Override those in child components of App if necessary */}
			<link rel="canonical" href={canonicalUrl} />
			<meta property="og:url" content={canonicalUrl} />
		</Helmet>
	);
}
