import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import webappInfo from 'src/webappInfo';

import { ShortUrl, type ShortUrlMutation, type ShortUrlMutationVariables } from './getShortUrl.graphql';

export async function getShortUrl(longUrl: string, { gql: { fetch } }: UtilityContext): Promise<string> {
	if (webappInfo.proxyTarget?.root) {
		// link shortener currently doesn't support local-change
		// eslint-disable-next-line no-param-reassign
		longUrl = longUrl.replace(/^https?:\/\/[^/]*\//, `${webappInfo.proxyTarget.root}/`);
	}

	const { data } = await fetch<ShortUrlMutation, ShortUrlMutationVariables>({
		query: ShortUrl,
		variables: {
			url: longUrl,
			hashLength: 10,
		},
		rejectOnError: false,
	});

	if (!data?.shortenLink.shortUrl) {
		throw new Error(`Could not generate short url from ${longUrl} (reason: ${data?.shortenLink.message || 'unknown'})`);
	}
	return data.shortenLink.shortUrl;
}
