import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { PaymentMethodUsage } from '@change-corgi/middle-end-gql-schema';

import { saveDLocalPaymentMethod, setupDLocalPaymentMethod } from 'src/app/shared/utils/payments';
import type { TokenizationData } from 'src/app/shared/utils/payments';

type Params = {
	token: string;
	tokenType: 'temporary' | 'permanent';
	accountId?: string;
	usage: PaymentMethodUsage;
	shouldSavePaymentMethod?: boolean;
	customer?: {
		name?: string;
		countryCode?: string;
		document?: string;
		email?: string;
	};
};

export function useDLocalPermanentPaymentMethod(): (params: Params) => Promise<TokenizationData | undefined> {
	const utilityContext = useUtilityContext();
	// eslint-disable-next-line complexity
	return async (params: Params): Promise<TokenizationData | undefined> => {
		const { token, tokenType, shouldSavePaymentMethod, usage, customer } = params;

		let setupResponse;

		let result: TokenizationData | undefined;

		if (tokenType === 'temporary') {
			setupResponse = await setupDLocalPaymentMethod(
				{
					token,
					usage,
					name: customer?.name as string,
					countryCode: customer?.countryCode as string,
					document: customer?.document as string,
				},
				utilityContext,
			);

			result = {
				token: setupResponse.id,
				tokenType: 'permanent',
				accountId: setupResponse.accountId,
			};
			if (!shouldSavePaymentMethod) {
				return result;
			}
		}

		if (shouldSavePaymentMethod) {
			await saveDLocalPaymentMethod(
				tokenType === 'temporary' ? (setupResponse?.id as string) : token,
				usage,
				tokenType === 'temporary' ? (setupResponse?.accountId as string) : (params.accountId as string),
				customer?.email as string,
				utilityContext,
			);
		}

		return result;
	};
}
