import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconVisibility } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useTrackBannerActionClick, useTrackBannerView } from '../hooks/useTrackingStarterDashboard';

export type BannerProps = {
	location: 'home' | 'petition';
	bannerContext: { petitionId: string; petitionSlug: string };
};

export function StarterDashPromptingBanner({
	location,
	bannerContext: { petitionId, petitionSlug },
}: BannerProps): JSX.Element | null {
	const trackClick = useTrackBannerActionClick(location, petitionId);
	useTrackBannerView(location, petitionId);

	return (
		<Box backgroundColor="secondary-purple" data-testid="dash-prompt-banner">
			<Flex
				sx={{
					flexDirection: ['column', 'row'],
					justifyContent: ['flex-start', 'space-between'],
					alignItems: 'center',
					padding: ['24px 16px', '16px 40px'],
				}}
			>
				<Flex sx={{ alignItems: 'center', margin: ['0 0 16px 0', '0 16px 0 0'] }}>
					<Icon icon={iconVisibility} color="#fff" size="xl" sx={{ marginRight: '16px' }} />
					<Text mx="xs" color="white" sx={{ display: 'flex' }}>
						<Translate value="corgi.components.banner.starter_dashboard_prompt.text" />
					</Text>
				</Flex>
				<ButtonLink
					data-testid="dash-prompt-redirect-button"
					to={`/p/${petitionSlug}/dashboard`}
					onClick={trackClick}
					variant="secondary"
					size="small"
					sx={{ width: ['100%', '300px'] }}
				>
					<Translate value="corgi.components.banner.starter_dashboard_prompt.cta_btn_text" />
				</ButtonLink>
			</Flex>
		</Box>
	);
}
