import { useMemo } from 'react';

import { useCountryCode } from 'src/app/shared/hooks/l10n';
import { useCurrentUserAsync, useSessionAsync } from 'src/app/shared/hooks/session';
import { useMappedLoadedState } from 'src/app/shared/hooks/state';
import { isLoaded } from 'src/app/shared/utils/async';

export function useCountryCodes(): readonly string[] {
	const countryCode = useCountryCode();
	const userCountryCodeState = useMappedLoadedState(useCurrentUserAsync(), ({ value }) => ({
		value: value?.country?.countryCode,
	}));
	const userCountryCode = isLoaded(userCountryCodeState) ? userCountryCodeState.value : undefined;
	const ipCountryCodeState = useMappedLoadedState(useSessionAsync(), ({ value }) => ({
		value: value.ipCountry?.countryCode,
	}));
	const ipCountryCode = isLoaded(ipCountryCodeState) ? ipCountryCodeState.value : undefined;
	return useMemo(
		() => [countryCode, userCountryCode, ipCountryCode].filter((code): code is string => !!code),
		[countryCode, userCountryCode, ipCountryCode],
	);
}
