import { useCallback } from 'react';

import type { TrackingProperties } from '@change-corgi/tracking-properties';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useLocale } from 'src/app/shared/hooks/l10n';

import { useCanReportComment } from '../../../shared/hooks/useCanReportComment';
import type { SupporterComment } from '../../../shared/types';

type Result = ModelHookResult<
	{ canReportComment: boolean },
	{
		handleReportClick: () => void;
	}
>;

export function useReportComment({ petitionId, comment }: { petitionId: string; comment: SupporterComment }): Result {
	const { track } = useCampaignTracking();
	const locale = useLocale();
	const allowedLocales = ['en-US', 'en-GB'];

	const { canReportComment, currentUser } = useCanReportComment(comment);

	const handleReportClick = useCallback(() => {
		let payload: TrackingProperties = {
			petition_id: petitionId,
			reporter_id: currentUser?.id,
		};
		payload =
			comment.__typename === 'PetitionVideoComment'
				? { ...payload, video_id: comment.id, video_creator_id: comment.user.id }
				: { ...payload, comment_id: comment.id, comment_creator_id: comment.user.id };

		void track('supporter_voice_report_button_click', payload);
	}, [currentUser, petitionId, track, comment]);

	return {
		data: { canReportComment: canReportComment && allowedLocales.includes(locale) },
		actions: {
			handleReportClick,
		},
	};
}
