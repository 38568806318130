import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { FCM_SUPPORTER_VOICES_DETAILS_PAGE } from '@change-corgi/fcm/configs';

import { getPetitionId } from 'src/app/shared/api/petitions';

import type { SupporterVoicesConfig } from './types';

export const getSupporterVoicesConfig = async (
	utilityContext: UtilityContext,
	slugOrId: string,
): Promise<SupporterVoicesConfig> => {
	let petitionId: string;
	if (Number(slugOrId)) petitionId = slugOrId;
	else petitionId = (await getPetitionId(slugOrId, utilityContext)) || '';

	const { supporterVoicesDetailsPageConfig } = await utilityContext.fcm.get({
		supporterVoicesDetailsPageConfig: FCM_SUPPORTER_VOICES_DETAILS_PAGE,
	});

	if (!supporterVoicesDetailsPageConfig)
		return {
			supporterVoicesEnabled: false,
			sortByScoreEnabled: false,
		};

	const { enabled, blocklist, openVideoCreationFromPetitionDetails, sortByScore } = supporterVoicesDetailsPageConfig;

	const isInBlocklist = blocklist.some((item) => item.id === petitionId);

	const supporterVoicesEnabled = Boolean(enabled) && !isInBlocklist;

	const sortByScoreAllowList = !!sortByScore?.petitions.find((obj) => {
		return obj.id === petitionId;
	});

	const sortByScoreActivatedPercentage = sortByScore?.activatedPercentage || 0;
	const petitionLastTwoDigits = Number(petitionId.slice(-2));
	const sortByScoreActivated = petitionLastTwoDigits < sortByScoreActivatedPercentage;

	return {
		supporterVoicesEnabled,
		openVideoCreationFromPetitionDetails,
		sortByScoreEnabled: sortByScoreAllowList || sortByScoreActivated,
	};
};
