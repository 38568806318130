import { Button } from '@change/tomato-core-ui';

import { Translate } from '@change-corgi/core/react/i18n';

import { useSAPButton } from './hooks/useSAPButton';

export const SAPButton = (): React.JSX.Element | null => {
	const {
		data: { isSpecialLocale, sapLink, hideSAPButton },
		actions: { trackSapNavigation },
	} = useSAPButton();

	if (hideSAPButton) {
		return null;
	}

	return (
		<>
			{!isSpecialLocale ? (
				<Button
					as="a"
					data-qa="header-sap-button"
					href={sapLink}
					onClick={() => trackSapNavigation('button')}
					size="small"
					variant="secondary"
				>
					<div>
						<Translate value="fe.components.header.start_a_petition" />
					</div>
				</Button>
			) : (
				<>
					<Button
						as="a"
						className="block sm:hidden"
						data-qa="header-sap-link"
						href={sapLink}
						onClick={() => trackSapNavigation('link')}
						size="small"
						variant="tertiary"
					>
						<div className="whitespace-normal text-end">
							<Translate value="fe.components.header.start_a_petition" />
						</div>
					</Button>
					<Button
						as="a"
						className="hidden sm:block"
						data-qa="header-sap-button"
						href={sapLink}
						onClick={() => trackSapNavigation('button')}
						size="small"
						variant="secondary"
					>
						<div>
							<Translate value="fe.components.header.start_a_petition" />
						</div>
					</Button>
				</>
			)}
		</>
	);
};
