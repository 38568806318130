import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	SignatureByPetitionSlugOrIdQuery,
	SignatureByPetitionSlugOrIdQueryVariables,
} from './signatureByPetitionSlugOrId.graphql';
import { SignatureByPetitionSlugOrId } from './signatureByPetitionSlugOrId.graphql';
import type { PetitionSignature } from './types';

// eslint-disable-next-line complexity
export async function getViewerSignature(
	slugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionSignature | undefined> {
	const { data } = await fetch<SignatureByPetitionSlugOrIdQuery, SignatureByPetitionSlugOrIdQueryVariables>({
		query: SignatureByPetitionSlugOrId,
		variables: {
			slugOrId,
		},
		rejectOnError: true,
		path: '/viewerSignature',
		batched: false, // to ensure this rate limited mutation is not batched
	});
	if (
		data?.viewer.signatureByPetitionSlugOrId.__typename === 'SignatureByPetitionSlugOrIdFailure' ||
		!data?.viewer.signatureByPetitionSlugOrId.signedPetition ||
		!data.viewer.user?.id
	) {
		return undefined;
	}
	return {
		...data.viewer.signatureByPetitionSlugOrId.signedPetition,
		message: data.viewer.signatureByPetitionSlugOrId.signedPetition.message || '',
		userId: data.viewer.user.id,
	};
}
