import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { Button } from '@change-corgi/design-system/components/actions';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { createModalHook } from '@change-corgi/design-system/modals';
import { Text } from '@change-corgi/design-system/typography';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

import { deleteSupporterVideo } from 'src/app/shared/api/supporterVoices';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';
import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { DeleteVideoMessage } from '../../../components/DeleteVideoMessage';
import type { PageRenderingButton, SupporterVideo } from '../../types';

export const useDeleteVideoModalBase = createModalHook<
	{
		petitionId: string;
		video: SupporterVideo;
		renderedOn: PageRenderingButton;
	},
	{ value: 'success' | 'error' | undefined }
>({
	name: 'Delete Video',
	title: 'Delete Video',
	body: function DeleteModalBody({ options: { video, petitionId, renderedOn }, closeModal }) {
		const [isDeleting, setIsDeleting] = useState(false);
		const [deleteMessage, setDeleteMessage] = useState<'success' | 'error' | undefined>(undefined);

		const utilityContext = useUtilityContext();

		const { track, trackError } = useCampaignTracking();
		const currentUser = useCurrentUserAsync();
		const userId = isLoaded(currentUser) ? currentUser.value?.id : undefined;

		useEffect(() => {
			if (deleteMessage === 'success') {
				setTimeout(() => {
					closeModal({ value: deleteMessage });
				}, 1000);
			}
		}, [deleteMessage, closeModal]);

		const trackingData = useMemo(() => {
			let tData: TrackingProperties = {
				petition_id: petitionId,
				deleter_id: userId,
				page: renderedOn,
			};
			tData = video.videoUrl
				? { ...tData, video_id: video.id, video_creator_id: video.user?.id }
				: { ...tData, comment_id: video.id, comment_creator_id: video.user?.id };
			return tData;
		}, [petitionId, video, renderedOn, userId]);

		const handleCancel = useCallback(() => {
			track('supporter_voices_delete_modal_cancel_button_click', {
				...trackingData,
			});
			closeModal({ value: deleteMessage });
		}, [closeModal, deleteMessage, track, trackingData]);

		const handleDelete = useCallback(async () => {
			track('supporter_voices_delete_modal_delete_button_click', {
				...trackingData,
			});
			setIsDeleting(true);
			try {
				const deleteResult = await deleteSupporterVideo({ videoId: video.id, utilityContext });
				if (deleteResult?.deleteComment?.success) {
					setDeleteMessage('success');
					track('supporter_voice_delete_success', {
						...trackingData,
					});
				} else {
					setDeleteMessage('error');
					track('supporter_voice_delete_error', {
						...trackingData,
						...(deleteResult?.deleteComment?.reason ? { errors: deleteResult.deleteComment.reason } : {}),
					});
				}
			} catch (err) {
				setDeleteMessage('error');
				trackError('supporter_voice_delete_error', err, { params: trackingData });
			} finally {
				setIsDeleting(false);
			}
		}, [video, track, trackError, trackingData, utilityContext]);

		return (
			<Box data-testid="delete-confirmation" sx={{ flexDirection: 'column' }}>
				<Text as="p" mb={32}>
					Are you sure you want to permanently delete {video.videoUrl ? 'video' : 'comment'} {video.id}?
				</Text>
				<Flex sx={{ justifyContent: 'center' }}>{isDeleting && <Loader mb={16} data-testid="delete-loader" />}</Flex>
				<DeleteVideoMessage message={deleteMessage} video={video} />
				{deleteMessage !== 'success' && (
					<Flex sx={{ width: '100%', flexDirection: ['column', 'row'], gap: 16, justifyContent: 'flex-end' }}>
						<Button
							variant="secondaryEmphasis"
							data-testid="confirm-cancel-btn"
							onClick={handleCancel}
							disabled={isDeleting}
						>
							Cancel
						</Button>
						<Button
							variant="primary"
							data-testid="confirm-delete-btn"
							onClick={handleDelete}
							disabled={isDeleting}
							sx={{ textTransform: 'uppercase' }}
						>
							Permanently Remove
						</Button>
					</Flex>
				)}
			</Box>
		);
	},

	closeButton: false,
	closeOnClickOutside: false,
});
