import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { CookiePrefsRequest } from '../../../types';

import { SetCookiePrefs } from './setCookiePrefs.graphql';
import type { SetCookiePrefsMutation, SetCookiePrefsMutationVariables } from './setCookiePrefs.graphql';

type SetCookiePrefsResponse = SetCookiePrefsMutation['setCookiePrefs'];

export async function setCookiePrefs(
	prefs: CookiePrefsRequest,
	{ gql: { fetch } }: UtilityContext,
): Promise<SetCookiePrefsResponse> {
	const { data } = await fetch<SetCookiePrefsMutation, SetCookiePrefsMutationVariables>({
		query: SetCookiePrefs,
		variables: {
			input: {
				analytics: {
					text: prefs.analytics.consent_text,
					consentGiven: prefs.analytics.status,
					name: 'analytics',
				},
				marketing: {
					text: prefs.marketing.consent_text,
					consentGiven: prefs.marketing.status,
					name: 'marketing',
				},
				preferences: {
					text: prefs.preferences.consent_text,
					consentGiven: prefs.preferences.status,
					name: 'preferences',
				},
			},
		},
		rejectOnError: true,
	});
	if (!data) {
		throw new Error('Error saving cookie prefs');
	}
	return data.setCookiePrefs;
}
