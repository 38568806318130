import { z } from 'zod';

import { MediaTypes } from 'src/app/pages/startAPetition/shared/constants';

// the only required fields for publishing are `ask` and `description`
const askSchema = z.string().min(1);
const descriptionSchema = z.string().min(1);

const geoReachSchema = z.enum(['global', 'national', 'local']);
const locationSchema = z.object({ localizedName: z.string().min(1), googlePlacesId: z.string().min(1) });

const mediaSchema = z.object({
	url: z.string().min(1),
	type: z.nativeEnum(MediaTypes).optional(),
});

// used if the ai flow is disabled
const defaultSchema = z.object({
	aiPromptChoice: z.undefined(),
	ask: askSchema,
	description: descriptionSchema,
});

// used if the ai flow is enabled
// the below two flows are forked based on the prompt choice selection: scratch or ai
const scratchSchema = z.object({
	aiPromptChoice: z.literal('scratch'),
	ask: askSchema,
	description: descriptionSchema,
});

const aiInputSchema = z.object({
	aiPromptChoice: z.literal('ai'),
	aiInput: z.object({ mainPrompt: z.string(), personalStory: z.string().optional() }).optional(),
	aiResult: z.object({
		ask: askSchema,
		description: descriptionSchema,
		originalAsk: z.string().optional(),
		originalDescription: z.string().optional(),
	}),
});

// both fields can be present, but only one is required based on the selected choice
const requiredInputSchema = z.discriminatedUnion('aiPromptChoice', [scratchSchema, aiInputSchema, defaultSchema]);

// the rest are optional fields
const optionalInputSchema = z.object({
	geoReach: geoReachSchema.optional(),
	location: locationSchema.nullable().optional(),
	media: mediaSchema.nullable().optional(),
});

// the input schema validates the users selection
const inputSchema = requiredInputSchema.and(optionalInputSchema);

// the output schema validates the input transformations
// and ensures the result matches the schema passed to the mutation
const outputSchema = z.object({
	ask: askSchema,
	description: descriptionSchema,
	aiAsk: z.string().nullable().optional(),
	aiDescription: z.string().nullable().optional(),
	geoReach: geoReachSchema.nullable().optional(),
	location: locationSchema.nullable().optional(),
	media: z
		.object({ photo: z.object({ srcUrl: z.string().min(1), type: z.nativeEnum(MediaTypes).optional() }) })
		.nullable()
		.optional(),
});

const transformBaseSchema = (input: z.infer<typeof inputSchema>) => {
	if (input.aiPromptChoice === 'ai') {
		// pull off the fields that aren't needed in the output
		const { aiPromptChoice, aiInput, aiResult, ...rest } = input;

		return {
			...rest,
			ask: aiResult.ask,
			description: aiResult.description,
			aiAsk: aiResult.originalAsk,
			aiDescription: aiResult.originalDescription,
		};
	}

	// pull off the fields that aren't needed in the output
	const { aiPromptChoice, ...rest } = input;

	return rest;
};

const transformMediaSchema = ({ media, ...rest }: ReturnType<typeof transformBaseSchema>) => {
	if (!media) return rest;

	return {
		...rest,
		media: { photo: { srcUrl: media.url, ...(media.type && { type: media.type }) } },
	};
};

export const schema = inputSchema.transform(transformBaseSchema).transform(transformMediaSchema).pipe(outputSchema);
export type Schema = z.infer<typeof schema>;
