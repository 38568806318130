import type { PetitionDetailsDataQuery } from './api.graphql';

// eslint-disable-next-line complexity
export function getPetitionStrengthScore(petition: NonNullable<PetitionDetailsDataQuery['petition']>): number {
	let totalPointsAchieved = 0;
	if (petition.description.length < 500) {
		totalPointsAchieved += 2;
	} else if (petition.description.length < 2000) {
		totalPointsAchieved += 3;
	} else if (petition.description.length >= 2000) {
		totalPointsAchieved += 5;
	}

	// Petition Image
	if (petition.photo?.petitionLarge) {
		totalPointsAchieved += 2;
	}

	// petition Image test (MITA)
	if (petition.hasHadShareImageBandit) {
		totalPointsAchieved += 1;
	}

	// More than 0 Decision Makers
	if ((petition.primaryDecisionMakerConnection.totalCount || 0) > 0) {
		totalPointsAchieved += 1;
	}

	// More than 1 category tag
	if ((petition.tagsConnection?.nodes.length || 0) > 1) {
		totalPointsAchieved += 1;
	}
	return totalPointsAchieved;
}
