import { useCallback, useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useFcm } from 'src/app/app/layout/shared/fcm';
import { useCurrentRouteConfig } from 'src/app/routes';
import { useLocale } from 'src/app/shared/hooks/l10n';
import { isLoading } from 'src/app/shared/utils/async';

import { LOCALES_FOR_SAP_LINK } from '../../../../../../shared/consts';

type Result = ModelHookResult<
	{ isSpecialLocale: boolean; sapLink: string; hideSAPButton: boolean },
	{ trackSapNavigation: (type: 'button' | 'link') => void }
>;

function useHideSapButtonExperimentFCM(): { variant: string; hideNavSapButtonPages: readonly string[] } {
	const { experiments } = useUtilityContext();

	const fcmState = useFcm();

	return useMemo(() => {
		if (isLoading(fcmState)) {
			return {
				variant: 'control',
				hideNavSapButtonPages: [],
			};
		}
		if (!fcmState.values.hideNavSapButtonPages) {
			return {
				variant: 'control',
				hideNavSapButtonPages: [],
			};
		}

		const experiment = experiments.get('hide_nav_sap_button');
		void experiment.treat();

		return {
			variant: experiment.variation,
			hideNavSapButtonPages: fcmState.values.hideNavSapButtonPages,
		};
	}, [experiments, fcmState]);
}

export function useSAPButton(): Result {
	const track = useTracking();
	const currentLocale = useLocale();
	const isSpecialLocale = LOCALES_FOR_SAP_LINK.has(currentLocale);

	const currentRoute = useCurrentRouteConfig();
	const routeId = currentRoute?.config.id;

	const { variant, hideNavSapButtonPages } = useHideSapButtonExperimentFCM();

	return {
		data: {
			isSpecialLocale,
			sapLink: '/start-a-petition?source_location=header',
			hideSAPButton: useMemo(() => {
				return variant === 'hide' && !!currentRoute && hideNavSapButtonPages.includes(currentRoute.config.id);
			}, [variant, currentRoute, hideNavSapButtonPages]),
		},
		actions: {
			trackSapNavigation: useCallback(
				(type) => {
					void track('nav_sap_click', {
						link_type: type,
						route_id: routeId ?? 'unknown',
					});
				},
				[track, routeId],
			),
		},
	};
}
