import type { Locale } from '@change-corgi/config/locales';
import type { Utilities } from '@change-corgi/core/react/utilityContext';

import {
	GammaSharedPetitionLocale,
	type GammaSharedPetitionLocaleQuery,
	type GammaSharedPetitionLocaleQueryVariables,
} from './petitionLocale.graphql';

export async function getPetitionLocale(
	slugOrId: string,
	utilities: Pick<Utilities, 'gql' | 'http'>,
): Promise<Locale | undefined> {
	const { data } = await utilities.gql.fetch<GammaSharedPetitionLocaleQuery, GammaSharedPetitionLocaleQueryVariables>({
		query: GammaSharedPetitionLocale,
		variables: { slugOrId },
		rejectOnError: false,
	});

	return data?.petition?.originalLocale.localeCode;
}
