import { useCallback, useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';

import {
	useMembershipBottomBannerData,
	useMembershipBottomBannerFcm,
	useMembershipBottomBannerState,
} from '../../../../../../context';

type Result = ModelHookResult<
	{
		buttonAmounts: ReadonlyArray<{ amount: number; amountLocalized: string; link: string }>;
		selectedAmount: { amount: number; amountLocalized: string };
		buttonText: string;
		otherLink: string;
		ctaButtonLink: string;
		paymentTerm: string;
	},
	{
		onClick: () => void;
	}
>;

type Props = {
	slug: string;
	onClick: () => void;
};

// eslint-disable-next-line max-lines-per-function
export function useButtonContainer(props: Props): Result {
	const track = useTracking();
	const { translate, amountToBaseUnits, amountFromBaseUnits, localizeCurrency } = useI18n();
	const {
		data: { currency, subscriptionConfig },
	} = useMembershipBottomBannerData();
	const { yearlyContributionsAmountsConfig } = useMembershipBottomBannerFcm();
	const [{ paymentTerm }] = useMembershipBottomBannerState();

	const encodedURI = useMemo(() => encodeURIComponent(props.slug), [props.slug]);

	const generateLink = useCallback(
		(amount: number) => {
			return `/p/${encodedURI}/psf/membership?source_location=petition_membership_popup&amount=${amount}&payment_term=${paymentTerm}`;
		},
		[encodedURI, paymentTerm],
	);

	// eslint-disable-next-line complexity
	const selectedAmount = useMemo(() => {
		const isYearlyEnabled = yearlyContributionsAmountsConfig?.enabled && paymentTerm === 'yearly';

		if (isYearlyEnabled && yearlyContributionsAmountsConfig.amounts.length > 1) {
			return {
				amount: amountFromBaseUnits(yearlyContributionsAmountsConfig.amounts[1], currency),
				amountLocalized: localizeCurrency(yearlyContributionsAmountsConfig.amounts[1], currency, {
					currencyDisplay: 'narrowSymbol',
					amountInBaseUnits: true,
				}),
			};
		}

		return {
			amount: subscriptionConfig?.defaultAmount.amount ?? 0,
			amountLocalized: subscriptionConfig?.defaultAmount.amountLocalized ?? '0',
		};
	}, [
		yearlyContributionsAmountsConfig,
		paymentTerm,
		subscriptionConfig?.defaultAmount.amount,
		subscriptionConfig?.defaultAmount.amountLocalized,
		localizeCurrency,
		amountFromBaseUnits,
		currency,
	]);

	const yearlyAmounts = useMemo(() => {
		return yearlyContributionsAmountsConfig?.enabled
			? yearlyContributionsAmountsConfig.amounts.map((amount) => ({
					amount: amountFromBaseUnits(amount, currency),
					amountLocalized: localizeCurrency(amount, currency, {
						currencyDisplay: 'narrowSymbol',
						amountInBaseUnits: true,
					}),
					link: generateLink(amount),
				}))
			: [];
	}, [yearlyContributionsAmountsConfig, amountFromBaseUnits, localizeCurrency, currency, generateLink]);

	const buttonAmounts = useMemo(() => {
		const isYearlyEnabled = yearlyContributionsAmountsConfig?.enabled && paymentTerm === 'yearly';
		if (isYearlyEnabled) {
			return yearlyAmounts;
		}

		return (
			subscriptionConfig?.amounts.map((amount) => ({
				amount: amount.amount,
				amountLocalized: amount.amountLocalized,
				link: generateLink(amountToBaseUnits(amount.amount, currency)),
			})) ?? []
		);
	}, [
		yearlyContributionsAmountsConfig,
		paymentTerm,
		yearlyAmounts,
		subscriptionConfig,
		generateLink,
		amountToBaseUnits,
		currency,
	]);

	const onClick = useCallback(() => {
		void track('petition_membership_popup_click');
		props.onClick();
	}, [track, props]);

	return {
		data: {
			selectedAmount,
			buttonAmounts,
			buttonText: translate('fe.pages.sponsors.continue'),
			otherLink: generateLink(0),
			ctaButtonLink: generateLink(amountToBaseUnits(selectedAmount.amount, currency)),
			paymentTerm,
		},
		actions: {
			onClick,
		},
	};
}
